<template>
  <div class="content7">
    <div class="main-content">
      <div class="card">
        <img src="../../assets/img/gif7.gif" alt="" />
        <div class="template7-info-content" id="template7-1">
          <div>{{model_chart_name1}}</div>
          <div v-html="chart_data_1"></div>
        </div>
        <div class="template7-info-content none" id="template7-2">
          <div id="template7-2-1">
            <div class="template7-title">{{model_chart_name2}}</div>
            <div v-html="chart_data_2"></div>
          </div>
        </div>
        <div class="template7-info-content none" id="template7-3">
          <div>{{model_chart_name3}}</div>
          <div v-html="chart_data_3"></div>
        </div>
        <div class="template7-info-content none" id="template7-4">
          <div>{{model_chart_name4}}</div>
          <div v-html="chart_data_4"></div>
        </div>
        <div class="template7-bottom-content">
          <div>{{extra_param_1}}</div>
        </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card2">
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/end.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams2 } from "@/utils/common";
import ApiServe from '@/api/index'
export default {
  name: "Template7",
  data() {
    return {
      device_id:null,
      extra_param_1:'',
      chart_data_1:'',
      model_chart_name1:'',
      chart_data_2:'',
      model_chart_name2:'',
      chart_data_3:'',
      model_chart_name3:'',
      chart_data_4:'',
      model_chart_name4:'',
      audioData:[],
      totalRecordTime:null,
      id:'',
      model_id:null,
      model_name:"",
      model_category:'',
      url_extra_params:null,
      ip:'',
      port:'',
      offset_x:'',
      offset_y:'',
      width:"",
      height:'',
      source:''
    };
  },
  created() {
    this.id = GetParams2().id || null
    this.model_id = GetParams2().model_id || 7
    this.model_name = GetParams2().model_name || null
    this.model_category = GetParams2().model_category || null
    this.url_extra_params = GetParams2().url_extra_params || null
    this.ip = GetParams2().ip || null
    this.port = GetParams2().port || null
    this.offset_x = GetParams2().offset_x || null
    this.offset_y = GetParams2().offset_y || null
    this.width = GetParams2().width || null
    this.height = GetParams2().height || null
    this.source = GetParams2().source || null
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { chart_data_1, audioData } = this;
      return {
        chart_data_1,
        audioData,
      };
    },
    newIpPort(){
      if(this.source == 'ali'){
        return 'https://recordvideoali.wedengta.com'
      }else{
        return 'https://recordvideo.wedengta.com'
      }
    }
  },
  watch: {
    allReady(val) {
      if (val.chart_data_1  && val.audioData.length>0) {
        if (this.ip && this.port) {
          const params = {
            ip:this.ip,
            port:this.port,
            offset_x:this.offset_x,
            offset_y:this.offset_y,
            width:this.width,
            height:this.height
          }
          ApiServe.recordstartPc(this.newIpPort,params).then(({code}) => {
            
          }).catch((err)=>{
            ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
          });
          this.load();
        } else {
          alert('没获取到ip,port')
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getDataPC(params).then(({data,code})=>{
        this.chart_data_1 = data.chart_data_1[0].data['早盘表现'][0]
        this.model_chart_name1 = data.chart_data_1[0].model_chart_name
        this.chart_data_2 = data.chart_data_2[0].data['午后操盘'][0]
        this.model_chart_name2 = data.chart_data_2[0].model_chart_name
        this.chart_data_3 = data.chart_data_3[0].data['热门题材'][0]
        this.model_chart_name3 = data.chart_data_3[0].model_chart_name
        this.chart_data_4 = data.chart_data_4[0].data['要闻速递'][0]
        this.model_chart_name4 = data.chart_data_4[0].model_chart_name
        this.extra_param_1 = data.extra_param_1[0]
        if (this.ip && this.chart_data_1.length==0) {
          ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
        }
      }).catch((err)=>{
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
        })  
    },
    // 获取旁白
    getAudio() {
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getVideoDataPC(params).then(({data,code})=>{
        this.aduioData = data || []
        // 视频录制时间  4秒间隔，5秒尾部动画
        this.totalRecordTime = 4 + 5 + data.reduce((pre,cur)=>{
          return pre + cur.scene_duration
        },0) 
        if (this.ip && this.aduioData.length ==0) {
          ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
        }
      })
    },
    //午后操盘的数据太多往上平移
    moveToTop(time){
      let domHeight1 = $('#template7-2').height();
      let domHeight2 = $('#template7-2-1').height();
      if(domHeight2>domHeight1){
        // let space = domHeight2 - domHeight1 + 16
        $('#template7-2-1').animate({bottom:0},time)
      }
    }, 
    endRecord(){
      let arr =[0,1+this.aduioData[0].scene_duration,2+this.aduioData[0].scene_duration+this.aduioData[1].scene_duration,3+this.aduioData[0].scene_duration+this.aduioData[1].scene_duration+this.aduioData[2].scene_duration,4+this.aduioData[0].scene_duration+this.aduioData[1].scene_duration+this.aduioData[2].scene_duration+this.aduioData[3].scene_duration]
      let audiosOrderList =  this.aduioData.map((item,index)=>{
        return {
          'audioLink':item.audio_link,
          'timeIndex':arr[index],
          'audioLen':item.audio_length,
        }
      })
      const data = {
        'id':this.id, 
        'model_id':this.model_id,
        'videoTotalTime':this.totalRecordTime,
        'videmBgm':"https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        'audiosOrderList':audiosOrderList
      }
      ApiServe.recordstopPc(this.newIpPort,{ip:this.ip,port:this.port}).then(()=>{
        ApiServe.combineaudioswithbgm(this.newIpPort,this.ip,this.port,data).then(()=>{
           ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        }).catch(()=>{
          ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        })
      }).catch(()=>{
        ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
      })
    },
    load() {
      const scene_duration1 = this.audioData[0].scene_duration
      const scene_duration2 = scene_duration1 + 1 + this.audioData[1].scene_duration
      const scene_duration3 = scene_duration2 +1+ this.audioData[2].scene_duration
      const scene_duration4 = scene_duration3+1+ this.audioData[3].scene_duration
      setTimeout( ()=> {
        $("#template7-1").addClass("animate__fadeOut");
      }, scene_duration1*1000);
      setTimeout(() => {
        $("#template7-1").addClass("none");
        $("#template7-2").removeClass("none");
        this.moveToTop(this.audioData[1].scene_duration*1000)
      }, (scene_duration1+1)*1000);          //间隔
      setTimeout( ()=> {
        $("#template7-2").addClass("animate__fadeOut");
      }, scene_duration2*1000);
      setTimeout( ()=> {
        $("#template7-2").addClass("none");
        $("#template7-3").removeClass("none");
      }, (scene_duration2+1)*1000);     //间隔
      setTimeout( ()=> {
        $("#template7-4").addClass("animate__fadeOut");
      }, (scene_duration3)*1000);
      setTimeout( ()=> {
        $("#template7-3").addClass("none");
        $("#template7-4").removeClass("none");
      }, (scene_duration3+1)*1000);   //间隔
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, scene_duration4*1000);
      setTimeout( ()=> {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.endVideo.play()
      }, (scene_duration4+1)*1000);
      // 总时间scene_duration4+1+5秒
      // 5秒最后一页视频，总时长外再等待5秒调取录屏结束服务接口
      if(this.ip){
        setTimeout( ()=> {
          this.endRecord()
        }, (this.totalRecordTime)*1000); 
      }
    },
  },
};
</script>
<style scoped lang="less">
@import '@/assets/css/common.less';
.card img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
.content7,.card {
  height: 100%;
  background: #3b2675;
  color: #000;
}
.main-content{
  height: 90%;
  border: 1px cadetblue;
  position: absolute;
  top: 5%;
  left:0;
  right: 0;
}
.template7-info-content{
  position: absolute;
  left: 12%;
  right: 12%;
  top: 7%;
  height: 48%;
  overflow: hidden;
}
.template7-bottom-content{
  position: absolute;
  left: 12%;
  right: 12%;
  top: 71%;
}
#template7-2-1{
  position: absolute;
}
</style>